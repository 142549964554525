@use "bootstrap";
@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";

html, body, main {
    height: 100%;
}

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #36364e;
}

.shadow {
    -webkit-box-shadow: 5px 5px 15px 5px #000000 !important;
    box-shadow: 5px 5px 15px 5px #000000 !important;
}

mat-card {
    @extend .shadow;
}

mat-form-field {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.pointer {
    cursor: pointer;
}

.grid {
    display: grid !important;
    flex: auto;
    grid-template-columns: repeat(2, 1fr);

    > :nth-child(odd) {
        font-weight: bold;
        grid-column: 1;
    }

    > :nth-child(even) {
        grid-column: 2;
    }
}
